import {
  AppbarContainer,
  AppbarHeader,
  AppbarHeaderImage,
} from "../../styles/appbar";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Actions from "./actions";
import { Dialog, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery } from "@mui/material";
import { useUIContext } from "../../context/ui";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ContactModal from "../ContactModal"
import theme, { Colors }  from "../../styles/theme";

export default function AppbarMobile({ matches }) {
  const { setDrawerOpen, setShowSearchBox } = useUIContext();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openM, setOpenM] = useState(false);

  const handleCloseM = () => setOpenM(false);

    useEffect(() => {
    const timer = setTimeout(() => {
      console.log("Function executed after a few minutes");
      setOpenM(true);
    }, 300000); 

    // Cleanup function to clear the timeout if the component unmounts before the timeout completes
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <AppbarContainer sx={{ marginLeft: "30px" }}>
      <IconButton onClick={() => setDrawerOpen(true)}>
        <MenuIcon />
      </IconButton>
      {/*  <AppbarHeader textAlign={"center"} variant="h4">
        My Bags
      </AppbarHeader> */}
      <Link to={"/home"}>
        <AppbarHeaderImage src="/images/branding/LogoIberolunaMobile.png"  alt="Viajes Iberoluna" title="Viajes Iberoluna"/>
      </Link>
      {/*  <IconButton onClick={() => setShowSearchBox(true)}>
        <SearchIcon />
      </IconButton> */}
      {/* <Actions matches={matches} /> */}

      <Dialog  open={openM} onClose={handleCloseM}>
        <DialogTitle sx={{ m: 0, p: 1 }}>
          <br></br>
          <Typography
            align="center"
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              color: Colors.orange_ibero,
              fontWeight: "Medium",
            }}
          >
            ¿Necesitas asesoría para tu viaje?
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseM}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
        <ContactModal onClick={handleCloseM}  matches={matches}></ContactModal>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions> */}
      </Dialog>
    </AppbarContainer>
  );
}
