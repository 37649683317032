import { useState } from "react";
import { Circuit, CircuitFavButton } from "../../../styles/circuit";
import { Tooltip } from "@mui/material";
import useDialogModal from "../../../hooks/useDialogModal";
// import CircuitDetail from "../circuitdetail";
import MapView from "../../mapview";
import CircuitMeta from "./CircuitMeta";
import CircuitMetaTitle from "./CircuitMetaTitle";
import PublicIcon from "@mui/icons-material/Public";
import { Colors } from "../../../styles/theme";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

export default function SingleCircuitDesktop({
  circuit,
  matches,
  tipo,
  opcionhome, tabFecha, recomendados=false
}) {
  /*  const [CircuitDetailDialog, showCircuitDetailDialog, closeCircuitDialog] =
    useDialogModal(CircuitDetail); */

  const [MapViewDialog, showMapViewDialog, closeMapView] =
    useDialogModal(MapView);

  const [showOptions, setShowOptions] = useState(false);

  const handleMouseEnter = () => {
    setShowOptions(true);
  };
  const handleMouseLeave = () => {
    setShowOptions(false);
  };
  return (
    <>
      <Circuit
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        tipo={tipo}
        elevation={4}
        sx={{          
          "&:hover": {   
            transform: "scale(1.02) perspective(0px)",        
              boxShadow: 20,
          }
        }}
      >
        <CircuitMetaTitle
          circuit={circuit}
          matches={matches}
          tipo={tipo}
          opcionhome={opcionhome}
          ofertas={tipo === "Ofertas"}
          tabFecha={tabFecha}
          recomendados={recomendados}
        ></CircuitMetaTitle>
        {!matches ? (
          !tipo === "Ofertas" ? (
            <>
              {/* {circuit.URL_Mapa !==
                "https://www.cloud-europamundo.com/img/maps/euros/../no_mapa.jpg" &&
              circuit.URL_Mapa !== "" ? (
                <CircuitFavButton
                  isfav={0}
                  onClick={() => showMapViewDialog(circuit.URL_Mapa)}
                >
                  <Tooltip placement="left" title="Mapa de ruta">
                    <PublicIcon fontSize="large" color={Colors.orange_ibero} />
                  </Tooltip>
                </CircuitFavButton>
              ) : (
                <div></div>
              )} */}
            </>
          ) : (
            <>
              {/* <CircuitFavButton
                isfav={0}
                // onClick={() => showMapViewDialog(circuit.URL_Mapa)}
                sx={{backgroundColor: Colors.blue_ibero}}

              >
                <Tooltip placement="left" title="Mapa de XXX">
                  <LocalOfferIcon fontSize="large" color={Colors.orange_ibero} />
                </Tooltip>
              </CircuitFavButton> */}
            </>
          )
        ) : (
          <></>
        )}

        {/*  <CircuitActionsWrapper show={showOptions || matches}>
          <Stack direction={matches ? "row" : "column"}>
            <CircuitActionButton>
              <Tooltip placement="left" title="Compartir">
                <ShareIcon />
              </Tooltip>
            </CircuitActionButton> */}
        {/* <CircuitActionButton onClick={() => showCircuitDetailDialog()}>
              <Tooltip placement="left" title="Full view">
                <FitScreenIcon color="primary" />
              </Tooltip>
            </CircuitActionButton> 
          </Stack>
        </CircuitActionsWrapper>*/}
        {/* {!matches ? ( */}
        {true ? (
          <></>
        ) : (
          <CircuitMeta circuit={circuit} tipo={tipo} matches={matches}  tabFecha={tabFecha}/>
        )}
      </Circuit>

      {/* <CircuitDetailDialog circuit={circuit} /> */}
      <MapViewDialog product={circuit} />
    </>
  );
}
