import React, { useEffect, useState } from 'react';

const Home = () => {
    const [results, setResults] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://iberoluna.bookingsservices.com/wse/api/GetSearchResults?query=granada=&dias=7&mes=2024-01&page=1');
                const data = await response.json();
                console.log(data);
                setResults(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            <h1>API Results</h1>
            {results.length > 0 ? (
                <ul>
                    {results.map((result) => (
                        <li key={result.id}>{result.name}</li>
                    ))}
                </ul>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default Home;
