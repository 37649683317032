import {
  Grid,
  List,
  ListItemText,
  Typography,
  Button,
  ListItemButton,
} from "@mui/material";
import { Box } from "@mui/system";
import { Colors } from "../../styles/theme";
import FacebookIcon from "@mui/icons-material/Facebook";

import InstagramIcon from "@mui/icons-material/Instagram";
import { FooterTitle } from "../../styles/footer";

import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { useState } from "react";
import axios from "axios";

export default function Footer() {
  const navigate = useNavigate();
  const [trm, setTrm] = useState(0);

  const handleOnClickResponsabilidad = useCallback(
    () => navigate("/condiciones", { replace: true }),
    [navigate]
  );

  const handleOnClickPqrs = useCallback(
    () => navigate("/pqrs", { replace: true }),
    [navigate]
  );

  const handleOnClickClausula = useCallback(
    () => navigate("/clausula", { replace: true }),
    [navigate]
  );

  const handleOnClickPoliticas = useCallback(
    () => navigate("/tratamientodatos", { replace: true }),
    [navigate]
  );
  const handleOnClickRetracto = useCallback(
    () => navigate("/politica", { replace: true }),
    [navigate]
  );

  useEffect(() => {
    // 👇️ only runs once
    fillTRM();
  }, []);

  const fillTRM = async () => {
    const url = "https://iberolunatravel.online/wse/api/getTRM";
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    setTrm(resp.data.TRM);
  };

  return (
    <Box
      sx={{
        background: Colors.blue_ibero,
        color: Colors.white,
        // p: { xs: 4, md: 10 },
        // pt: 12,
        // pb: 12,
        paddingTop: "25px",
        paddingBottom: "25px",
        paddingLeft: "7px",
        paddingRight: "5px",
        marginTop: "15px",
        fontSize: { xs: "12px", md: "14px" },
      }}
    >
      <Grid container spacing={8} justifyContent="center">
        <Grid item md={6} lg={5}>
          <FooterTitle variant="h6">VIAJES IBEROLUNA S.A.S.</FooterTitle>
          <Typography variant="body1">
            Expertos en turismo desde hace 30 años, con el apoyo de grandes
            operadores en los 5 Continentes, las mejores herramientas y equipo
            altamente capacitado, para dar las opciones de viaje más
            competitivas del mercado, con mayor respaldo para su tranquilidad y
            seguridad.
          </Typography>
          <br />
          <Typography variant="body1">
            Somos una empresa que no vende viajes sino EXPERIENCIAS. Creamos un
            turismo responsable a través del cual pretendemos transmitir la
            importancia de valorar y cuidar nuestro entorno, la tierra que nos
            rodea, distintas culturas, formas diferentes de enfrentarse a la
            vida. Todo esto gracias a los trabajadores que hacen posible
            transportar a los clientes un gran valor añadido que es la pasión
            por viajar.
          </Typography>
          <br></br>
          <br></br>
          <p>
            <strong>RNT.</strong> 36964
          </p>
          <p>
            <strong>NIT.</strong> 830.008.421-5
          </p>
          <p>
            <strong>PBX.</strong> (601) 2362803
          </p>
            <p>
            <strong>Medellín:</strong> 316 2958041
          </p>

          <p>
            <strong>Barranquilla:</strong> 313 5134810
          </p>
       
          <p>
            <strong>Cali:</strong> 315 4186793
          </p>
          <p>
            <strong>WhatsApp:</strong>{" "}
           
              +57 314 7007919
          </p>
          <p>
            <a
              aria-label="Chat on WhatsApp"
              href="https://api.whatsapp.com/send/?phone=%2B573147007919&text&type=phone_number&app_absent=0"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img
                alt="Chat on WhatsApp"
                src="/images/WhatsAppButtonGreenSmall.svg"
              />{" "}
            </a>
          </p>
          <p>
            <strong>Línea Nacional:</strong> 018000111974
          </p>
          <p>
            <strong>Dirección:</strong> Cra. 13 #85-39 of 202, Localidad de
            Chapinero, Bogotá
          </p>
          <p>
            <strong>Email:</strong> clientes@iberolunatravel.online
          </p>
          <br></br>
          <Link
            component="button"
            variant="BUTTON"
            color="inherit"
            onClick={() => {
              window.open("#/quienessomos", "_self");
            }}
          >
            QUIENES SOMOS
          </Link>

          <Box
            sx={{
              mt: 4,
              color: Colors.dove_gray,
            }}
          >
            <Button
              onClick={() =>
                window.open(
                  "https://www.facebook.com/Iberolunatravel/",
                  "_blank"
                )
              }
            >
              <FacebookIcon
                sx={{ color: Colors.orange_ibero, fontSize: "68px" }}
              />
            </Button>
            <Button
              onClick={() =>
                window.open(
                  "https://www.instagram.com/viajes_iberoluna/",
                  "_blank"
                )
              }
            >
              <InstagramIcon
                sx={{ mr: 1, color: Colors.orange_ibero, fontSize: "68px" }}
              />
            </Button>
            <Button  onClick={() =>
                window.open(
                  "https://anato.org/agencias/viajes-iberoluna-operador-mayorista/",
                  "_blank"
                )
              }>
              <img
                src={`/images/LogoANATO.png`}
                loading="lazy"
                width="68px"
                alt="Anato"
              ></img>
            </Button>
          </Box>
        </Grid>
        <Grid item md={6} lg={3}>
          <FooterTitle variant="body1">Términos y Condiciones</FooterTitle>
          <List dense={true}>
            <ListItemButton
              onClick={() =>
                window.open(
                  "https://www.iberolunatravel.com/contenidoweb/Paginas/ibero/Varios/Sostenibilidad.aspx",
                  "_blank"
                )
              }
            >
              <ListItemText>
                <Typography lineHeight={1} variant="caption2">
                  Sostenibilidad
                </Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton onClick={handleOnClickResponsabilidad}>
              <ListItemText>
                <Typography lineHeight={1} variant="caption2">
                  Condiciones Generales
                </Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton onClick={handleOnClickClausula}>
              <ListItemText>
                <Typography lineHeight={1} variant="caption2">
                  Cláusula de Responsabilidad
                </Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton onClick={handleOnClickPoliticas}>
              <ListItemText>
                <Typography lineHeight={1} variant="caption2">
                  Políticas de Privacidad
                </Typography>
              </ListItemText>
            </ListItemButton>

            <ListItemButton onClick={handleOnClickPqrs}>
              <ListItemText>
                <Typography lineHeight={1} variant="caption2">
                  PQRS / Retracto / Reversión
                </Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() =>
                window.open(
                  "https://teprotejocolombia.org/categorias-de-reporte/explotacion-sexual-comercial-escnna/",
                  "_blank"
                )
              }
            >
              <ListItemText>
                <Typography lineHeight={1} variant="caption2">
                  Ley contra la Explotación y abuso sexual de menores de edad
                </Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() =>
                window.open(
                  "/contenidos/politicas/PoliticaGeneralSAGRLAFT-V2.pdf",
                  "_blank"
                )
              }
            >
              <ListItemText>
                <Typography lineHeight={1} variant="caption2">
                  Políticas contra el lavado de activos y financiamiento del
                  terrorismo.
                </Typography>
              </ListItemText>
            </ListItemButton>
          </List>
        </Grid>
        <Grid item md={6} lg={3}>
          <FooterTitle variant="body1">Información de Interes</FooterTitle>
          <List dense={true}>
            <ListItemButton>
              <ListItemText>
                <Typography lineHeight={1} variant="caption2">
                  Tasa Representativa del Mercado: {trm}
                </Typography>
              </ListItemText>
            </ListItemButton>
            {/*   <ListItemButton
              onClick={() =>
                window.open(
                  "http://www.iberolunatravel.com/ContenidoWeb/Paginas/Ibero/home/pagos.aspx",
                  "_blank"
                )
              }
            >
              <ListItemText>
                <Typography lineHeight={1} variant="caption2">
                  Cuentas bancarias y formas de pago
                </Typography>
              </ListItemText>
            </ListItemButton> */}
            {/*   <ListItemButton
              onClick={() =>
                window.open(
                  "http://www.iberolunatravel.com/ContenidoWeb/Paginas/Ibero/otros/tramitacion-visas-ibero.aspx",
                  "_blank"
                )
              }
            >
              <ListItemText>
                <Typography lineHeight={1} variant="caption2">
                  Requisitos y formularios de Visas
                </Typography>
              </ListItemText>
            </ListItemButton> */}
          </List>
        </Grid>
        {/* <Grid item md={6} lg={4}>
          <FooterTitle variant="body1">newsletter</FooterTitle>
          <Stack>
            <SubscribeTf
              color="primary"
              label="Email address"
              variant="standard"
            />
            <Button
              startIcon={<SendIcon sx={{ color: Colors.white,}} />}
              sx={{ mt: 4, mb: 4, bgcolor: Colors.orange_ibero  }}
              variant="contained"
            >
              Subscribe
            </Button>
          </Stack>
        </Grid> */}
      </Grid>
      <Typography sx={{ textAlign: "right", paddingRight: "20px" }}>
        Elaborado por: <b>BookingsServices</b>
      </Typography>
    </Box>
  );
}
