import * as React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";

import { Colors } from "../../../../../styles/theme";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";

import { styled } from "@mui/material/styles";

function Incluye(props) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: Colors.blue_ibero,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableCellAlta = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: Colors.orange_ibero,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <Container
      component="section"
      sx={{
        mt: 10,
        mb: 10,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid container spacing={4}>          
         <Grid item xs={12} md={12}>
          <Typography
            variant="h4"
            marked="center"
            component="h2"
            align="center"
            sx={{ mb: 3 }}
          >
            Tarifas por persona
          </Typography>         
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 250 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                <StyledTableCell align="center">
                    Doble / Triple
                  </StyledTableCell>
                  <StyledTableCell align="center">Sencilla</StyledTableCell>       
                </TableRow>
              </TableHead>
              <TableBody>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row" align="center">
                    13.985.000
                    </StyledTableCell>
                    <StyledTableCell align="center">
                    17.271.000
                    </StyledTableCell>                   
                  </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Typography
            variant="subtitle1"
            align="center"
            sx={{  mt: 1 }}
          >
            ** Precios en pesos colombianos **
          </Typography>
        </Grid>       
      </Grid>
    </Container>
  );
}

export default Incluye;
