import {
  ThemeProvider,
} from "@mui/material";
import "../../App.css";
import { Container, Stack, Grid } from "@mui/material";
import theme from "../../styles/theme";
import Appbar from "../appbar";
import { useEffect } from "react";
import Footer from "../footer";
import { UIProvider } from "../../context/ui";
import AppDrawer from "../drawer";
import { Colors } from "../../styles/theme";
import { AppTitleOrange } from "../../styles/app";
import {  useNavigate } from "react-router-dom";
import LinkButton from "@mui/material/Link";

import { ImagePromotion } from "../../styles/promotions";

function Circuitsdetaildesktop(props) {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Viajes Iberoluna - Salidas Grupales";
  }, []);

  const renderProducts = props.mosaicos.map((product, i) => (
    <Grid
      item
      key={product.id}
      xs={2}
      sm={4}
      md={4}
      display="flex"
      flexDirection={"row"}
      alignItems="center"
    >
      {product.nombreUrl.toLowerCase().indexOf('pdf') >= 0 ? (         
      <LinkButton
        component="button"
        variant="body2"        
        onClick={() => {
          navigate("/programasmasvendidos/"+product.nombreUrl);
        }}
      >
        <ImagePromotion key={i} src={product.imagen} />
      </LinkButton>
      ):(  <LinkButton
        component="button"
        variant="body2"       
        onClick={() => {
          navigate("/"+product.nombreUrl);
        }}
      >
        <ImagePromotion key={i} src={product.imagen} />
      </LinkButton>)}
    </Grid>
  ));

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          background: "#fff",
          padding: "0px !important",
        }}
      >
        <Stack>
        <UIProvider>
        <Appbar />                      
            <Stack>
              <Stack
                direction={!props.matches ? "row" : "column"}
                spacing={2}
                justifyContent="space-between"
              >
                <Stack alignItems="center">
                  <br></br>
                  <br></br>
                  <br></br>
                  <AppTitleOrange variant={!props.matches ? "h2" : "h3"}>
                  Los mejores programas a Europa
                  </AppTitleOrange>
                  <br></br>
                  <br></br>
                  <Stack
                    sx={{
                      background: "#fff",
                      borderColor: Colors.blue_ibero,
                      padding: "15px",
                      borderRadius: "15px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      m: "15px"
                    }}
                  >
                    <Grid
                      container
                      spacing={{ xs: 1, md: 2 }}
                      justifyContent="center"
                      columns={{ xs: 4, sm: 6, md: 12 }}
                    >
                      {renderProducts}
                    </Grid>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <AppDrawer />
          </UIProvider>
        </Stack>
        <Footer />
      </Container>
    </ThemeProvider>
  );
}

export default Circuitsdetaildesktop;
