import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import CircuitsdetailDesktop from "./circuitsdetailDesktop";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//import AppbarMobile from "./titleMobile";

export default function Titlebar(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [lineasAereas, setLineasAereas] = useState([]);
  const [circuitoData, setCircuitoData] = useState([]);
  const [circuitoIncluye, setCircuitoIncluye] = useState([]);
  const [circuitoItinerario, setCircuitoItinerario] = useState([]);
  const [circuitoHoteles, setCircuitoHoteles] = useState([]);
  const [imagenes, setImagenes] = useState([]);
  const [circuitoFechas, setCircuitoFechas] = useState([]);
  const [circuitoFechas2, setCircuitoFechas2] = useState([]);
  const [circuitoFechas3, setCircuitoFechas3] = useState([]);
  const [circuitoFechas4, setCircuitoFechas4] = useState([]);
  const [circuitoFechas5, setCircuitoFechas5] = useState([]);
  const [circuitoFechas1, setCircuitoFechas1] = useState([]);
  const [fechasRecientes, setFechasRecientes] = useState([]);
  const [circuitoFechas6, setCircuitoFechas6] = useState([]);
  const [circuitoFechas7, setCircuitoFechas7] = useState([]);
  const [circuitoFechas8, setCircuitoFechas8] = useState([]);
  const [circuitoFechas9, setCircuitoFechas9] = useState([]);
  const [circuitoFechas10, setCircuitoFechas10] = useState([]);
  const [circuitoFechas11, setCircuitoFechas11] = useState([]);
  const [meses, setMeses] = useState([]);
  const [flag, setFlag] = useState(false);
  const [contadorMes, setContadorMes] = useState(0);
  const [logid, setLogid] = useState(0);
  const [tabFecha, setTabFecha] = useState(0);
  const [flagWait, setFlagWait] = useState(false);
  const params = useParams();
  const idV = params.idviaje;
  const fechaSeleccion = params.fecha;

  function create_UUID() {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  useEffect(() => {
    let uuid = create_UUID();
    setLogid(uuid);
    window.scrollTo(0, 0);
    fillViaje();
    fillIncluye();
    fillItinerario();
    fillHoteles();
    fillFechas(uuid);
    fillLineasAereas();
    setFlag(true);
  }, []);

  /*  useEffect(() => {
    console.log('useEffect circtuitdetails');
    if (!flag) {
      fillViaje();
      fillIncluye();
    console.log('useEffect circtuitdetails XXXX');

    }
    setFlag(true);
   //fillItinerario();
  }, [flag]); */

  const fillFechas = (uuid) => {
    //var GivenDate = "2022-10-19";
    var GivenDate = new Date();
    var CurrentDate = new Date();
    //GivenDate = [CurrentDate.getFullYear(), CurrentDate.getMonth(), CurrentDate.getDate()].join('-');

    // var fechasBusquedas = [];

    GivenDate = new Date(GivenDate);
    CurrentDate.setDate(CurrentDate.getDate() + 4);

    /* if (GivenDate > CurrentDate) {
      GivenDate.setMonth(GivenDate.getMonth() - 1);
    } else {
      GivenDate.setMonth(GivenDate.getMonth() - 2);
    } */

    GetFechaReciente(
      uuid,
      [
        CurrentDate.getFullYear(),
        CurrentDate.getMonth() + 1,
        CurrentDate.getDate(),
      ].join("-")
    );

    /* 
    for (let i = 0; i < 12; i++) {
      fechasBusquedas.push(
        new Date(GivenDate.setMonth(GivenDate.getMonth() + 1))
      );
    } */

    /* const monthNames = [
      "ENERO",
      "FEBRERO",
      "MARZO",
      "ABRIL",
      "MAYO",
      "JUNIO",
      "JULIO",
      "AGOSTO",
      "SEPTIEMBRE",
      "OCTUBRE",
      "NOVIEMBRE",
      "DICIEMBRE",
    ];
    meses.length = 0;
    fechasBusquedas.forEach(function (fecha, key) {
      meses.push(monthNames[fecha.getMonth()]+ " " + fecha.getFullYear());
      GetFechas(
        fecha.getFullYear() +
          (fecha.getMonth() + 1).toString().padStart(2, "0"),
        key
      );
    }); */
  };

  const GetFechaReciente = async (uuid, fecA) => {
    const monthNames = [
      "ENERO",
      "FEBRERO",
      "MARZO",
      "ABRIL",
      "MAYO",
      "JUNIO",
      "JULIO",
      "AGOSTO",
      "SEPTIEMBRE",
      "OCTUBRE",
      "NOVIEMBRE",
      "DICIEMBRE",
    ];
    //BUSCA FECHA MAS RECIENTE DEL VIAJE
    const url =
      "https://iberolunatravel.online/wse/api/GetFechaReciente?id=" +
      idV +
      "&fecha=" +
      fecA +
      "&tipo=" +
      props.tipo;
    axios
      .get(url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setFechasRecientes(response.data);
        meses.length = 0;
        let a = 0;

        response.data.forEach(function (fecha, key) {          
          // if (fecha.ano + fecha.mes.toString().padStart(2, "0") === fechaSeleccion) {
          //   setTabFecha(key);
          // } 
          meses.push(monthNames[fecha.mes - 1] + " " + fecha.ano);
         
          a++;
          if (a <= 4)
          {
            GetFechas(
              uuid,
              fecha.ano + fecha.mes.toString().padStart(2, "0"),
              key
            );
          } else
          { 
            if (a <= 8)
            {
              setTimeout(() => {  GetFechas(
                uuid,
                fecha.ano + fecha.mes.toString().padStart(2, "0"),
                key
              ); }, 3000);
            } else
            {
              setTimeout(() => {  GetFechas(
                uuid,
                fecha.ano + fecha.mes.toString().padStart(2, "0"),
                key
              ); }, 5500);
            }
          }                     
        });
        /*        console.log("meses");
        console.log(meses); */
      });

    /*   const respX =  await axios.get(url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
    */
  };

  const GetFechas = async (uuid, fecha, id) => {
    const url =
      "https://iberolunatravel.online/wse/api/getfechascircuito?id=" +
      idV +
      "&fecha=" +
      fecha +
      "&resumen=" +
      matches +
      "&idFilter=" +
      id +
      "&logid=" +
      uuid +
      "&tipo=" +
      props.tipo;
      
      let retryCount = 0;
      while (retryCount <= 1) {
        try {
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
    if (resp.data !== null) {
      switch (id) {
        case 0:
          setCircuitoFechas(resp.data);
          break;
        case 1:
          setCircuitoFechas1(resp.data);
          break;
        case 2:
          setCircuitoFechas2(resp.data);
          break;
        case 3:
          setCircuitoFechas3(resp.data);
          break;
        case 4:
          setCircuitoFechas4(resp.data);
          break;
        case 5:
          setCircuitoFechas5(resp.data);
          break;
        case 6:
          setCircuitoFechas6(resp.data);
          break;
        case 7:
          setCircuitoFechas7(resp.data);
          break;
        case 8:
          setCircuitoFechas8(resp.data);
          break;
        case 9:
          setCircuitoFechas9(resp.data);
          break;
        case 10:
          setCircuitoFechas10(resp.data);
          break;
        case 11:
          setCircuitoFechas11(resp.data);
          break;
        default:
          break;
      }
      retryCount=99;
    } else {
      // API call failed, increment retry count and wait before retrying
      retryCount++;
      console.error('API request failed. Retrying...');
      await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 1 second before retrying
  }
  } catch (error) {
    // Handle any other errors
    console.error('Error:', error);
    await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 1 second before retrying
    retryCount++;
}
  }
  };

  const fillItinerario = async () => {
    setFlagWait(true);
    const url =
      "https://iberolunatravel.online/wse/api/getitinerariocircuito?id=" +
      idV +
      "";
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    var videos = [];
    resp.data.forEach(function (entry) {
      // console.log(entry);
      if (entry.VIDEO !== "") {
        videos.push({
          original: entry.VIDEO,
          thumbnail: entry.VIDEO,
        });
      }
    });

    setCircuitoItinerario(resp.data);
    setFlagWait(false);
  };

  const fillIncluye = async () => {
    const url =
      "https://iberolunatravel.online/wse/api/getincluyecircuito?id=" +
      idV +
      "";
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    setCircuitoIncluye(resp.data);
  };

  const fillHoteles = async () => {
    const url =
      "https://iberolunatravel.online/wse/api/gethotelescircuito?id=" +
      idV +
      "";
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Headers": "Content-Type, application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
      },
    });

    setCircuitoHoteles(resp.data);
  };

  const fillLineasAereas = async () => {
    const url = "https://iberolunatravel.online/wse/api/getlineasaereas";
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    setLineasAereas(resp.data);
  };

  const fillViaje = async () => {
    const url =
      "https://iberolunatravel.online/wse/api/getcircuito?id=" + idV + "";
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    //var images = resp.data.IMAGENES.split("ö");

    var images = [];
    resp.data.IMAGENES.split("ö").forEach(function (entry) {
      if (entry !== "") {
        images.push({
          original: entry,
          thumbnail: entry,
        });
      }
    });
    setImagenes(images);
    setCircuitoData(resp.data);
  };

  return (
    <>
      {matches ? (
        <CircuitsdetailDesktop
          key={"CD"}
          logid={logid}
          fechas={circuitoFechas}
          fechas1={circuitoFechas1}
          fechas2={circuitoFechas2}
          fechas3={circuitoFechas3}
          fechas4={circuitoFechas4}
          fechas5={circuitoFechas5}
          fechas6={circuitoFechas6}
          fechas7={circuitoFechas7}
          fechas8={circuitoFechas8}
          fechas9={circuitoFechas9}
          fechas10={circuitoFechas10}
          fechas11={circuitoFechas11}
          meses={meses}
          circuitoData={circuitoData}
          circuitoIncluye={circuitoIncluye}
          imagenes={imagenes}
          matches={matches}
          itinerario={circuitoItinerario}
          hoteles={circuitoHoteles}
          flagWait={flagWait}
          tipo={props.tipo}
          tabFecha={tabFecha}
        />
      ) : (
        <CircuitsdetailDesktop
          key={"CD-C"}
          logid={logid}
          fechas={circuitoFechas}
          fechas1={circuitoFechas1}
          fechas2={circuitoFechas2}
          fechas3={circuitoFechas3}
          fechas4={circuitoFechas4}
          fechas5={circuitoFechas5}
          fechas6={circuitoFechas6}
          fechas7={circuitoFechas7}
          fechas8={circuitoFechas8}
          fechas9={circuitoFechas9}
          fechas10={circuitoFechas10}
          fechas11={circuitoFechas11}
          meses={meses}
          circuitoData={circuitoData}
          circuitoIncluye={circuitoIncluye}
          matches={matches}
          imagenes={imagenes}
          itinerario={circuitoItinerario}
          hoteles={circuitoHoteles}
          lineasaereas={lineasAereas}
          flagWait={flagWait}
          tipo={props.tipo}
          tabFecha={tabFecha}
        />
      )}
    </>
  );
}
