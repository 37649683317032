import * as React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import { Colors } from "../../../../../styles/theme";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { styled } from "@mui/material/styles";
import StarRateIcon from '@mui/icons-material/StarRate';

function Hoteles(props) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: Colors.blue_ibero,
      color: theme.palette.common.white,
      fontSize: 18,      
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: 0,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <Container
      component="section"
      sx={{
        mt: 10,
        mb: 10,       
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Typography
            variant="h4"
            marked="center"
            component="h2"
            sx={{ mb: 3 }}
            align="center"
          >
            Hoteles previstos o similares
          </Typography>
          <TableContainer  component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Noches</StyledTableCell>
                  <StyledTableCell align="center">Ciudades</StyledTableCell>
                  <StyledTableCell align="center">Hoteles</StyledTableCell>
                  <StyledTableCell align="center">Categoría</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row" align="center">
                    2
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    Madrid
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                  Hampton by Hilton Alcobendas
                  </StyledTableCell>                 
                  <StyledTableCell align="center">
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell component="th" scope="row" align="center">
                    1
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    Barcelona
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                  Exe Barbera Parc
                  </StyledTableCell>                 
                  <StyledTableCell align="center">
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell component="th" scope="row" align="center">
                    1
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                  Costa Azul
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                  Amarante
                  </StyledTableCell>                 
                  <StyledTableCell align="center">
                  <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell component="th" scope="row" align="center">
                    2
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    Roma
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                  Black
                  </StyledTableCell>                 
                  <StyledTableCell align="center">
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell component="th" scope="row" align="center">
                    1
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    Florencia
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                  Miro
                  </StyledTableCell>                 
                  <StyledTableCell align="center">
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell component="th" scope="row" align="center">
                    1
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    Venecia (Mestre)
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                  Noventa Di Piave
                  </StyledTableCell>                 
                  <StyledTableCell align="center">
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell component="th" scope="row" align="center">
                    1
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    Annemasse
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                  Campanile Centre
                  </StyledTableCell>                 
                  <StyledTableCell align="center">
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell component="th" scope="row" align="center">
                    2
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    París
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                  Campanile Bagnolet
                  </StyledTableCell>                 
                  <StyledTableCell align="center">
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell component="th" scope="row" align="center">
                    1
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    Lourdes
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                  Croix Des Bretons
                  </StyledTableCell>                 
                  <StyledTableCell align="center">
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell component="th" scope="row" align="center">
                    3
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    Madrid
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                  Hampton by Hilton Alcobendas
                  </StyledTableCell>                 
                  <StyledTableCell align="center">
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                    <StarRateIcon sx={{ color: Colors.orange_ibero }} />
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Hoteles;
