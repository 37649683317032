import { Badge, Box, Chip, Stack } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Colors } from "../../styles/theme";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const OurFeatures = () => {
    return (
    <Box sx={{ width: '100%' }}>
      <Stack direction="row" spacing={2}>
            <div>
                <img src="/images/our-features.webp" alt="Feature" style={{maxWidth: "800px"}}/>
            </div>
            <div>
                {/* <ul className="space-y-10 mt-10">
                    <li className="space-y-4">
                        <Chip label="Experiencia" color="primary" />
                        <span className="block text-xl font-semibold">
                        30 años de Experiencia
                        </span>
                        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                        En Viajes Iberoluna llevamos más de tres décadas ofreciendo no solo viajes, sino vivencias inolvidables. Nos dedicamos a distribuir paquetes y circuitos turísticos a las principales agencias minoristas de todo el país. Nuestro enfoque va más allá de vender destinos; nos comprometemos a crear un turismo responsable que resalte la importancia de valorar y preservar nuestro entorno, promoviendo el respeto por distintas culturas y formas de vida.
                        </span>
                    </li>
                    <li className="space-y-4">
                        <span className="block text-xl font-semibold">
                        Tenemos los mejores proveedores
                        </span>
                        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                        Somos representantes de Europamundo en Colombia y contamos con el apoyo de prestigiosos operadores europeos y el mundo. Lo que nos permite ofrecer programas especiales a destinos como Oriente, África y Australia.
                        </span>
                    </li>
                    <li className="space-y-4">
                        <span className="block text-xl font-semibold">
                        Mejor relación precio valor
                        </span>
                        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                        Nuestras alianzas comerciales nos permiten bridar tarifas muy competitivas en el mercado.
                        </span>
                    </li>
                </ul> */}
                <Stack spacing={2}>
                <div style={{paddingTop: "60px"}}>
                    {/* <div style={{textAlign: "left"}}>
                    <Chip label="Experiencia" color="primary" icon={<CheckCircleOutlineIcon />} sx={{paddingLeft:"25px", paddingRight: "25px", fontSize: "20px", margin: "20px"}}  />
                    </div> */}
                    <h2 style={{color: Colors.blue_ibero, marginBottom: "15px"}}>
                        30 años de experiencia
                    </h2>
                    <p style={{fontSize: "18px", color: "GrayText"}}>En Viajes Iberoluna llevamos más de tres décadas ofreciendo no solo viajes, sino vivencias inolvidables. Nos dedicamos a distribuir paquetes y circuitos turísticos a las principales agencias minoristas de todo el país. Nuestro enfoque va más allá de vender destinos; nos comprometemos a crear un turismo responsable que resalte la importancia de valorar y preservar nuestro entorno, promoviendo el respeto por distintas culturas y formas de vida.</p>
                </div>  
                <div>
                    {/* <div style={{textAlign: "left"}}>
                        <Chip label="Confianza" color="primary" icon={<CheckCircleOutlineIcon />} sx={{paddingLeft:"25px", paddingRight: "25px", fontSize: "20px", margin: "20px"}}  />
                    </div> */}
                    <h2 style={{color: Colors.blue_ibero, marginBottom: "15px", marginTop: "30px"}}>Tenemos los mejores proveedores</h2>
                    <p style={{fontSize: "18px", color: "GrayText"}}>Somos representantes de Europamundo en Colombia y contamos con el apoyo de prestigiosos operadores europeos y el mundo. Lo que nos permite ofrecer programas especiales a destinos como Oriente, África y Australia.</p>
                </div>   
                <div>
                    {/* <div style={{textAlign: "left"}}>
                        <Chip label="Mejores precios" color="primary" icon={<CheckCircleOutlineIcon />} sx={{paddingLeft:"25px", paddingRight: "25px", fontSize: "20px", margin: "20px"}}  />
                    </div>    */}
                    <h2 style={{color: Colors.blue_ibero, marginBottom: "15px", marginTop: "30px"}}>Mejor relación precio valor</h2>
                    <p style={{fontSize: "18px", color: "GrayText"}}>Nuestras alianzas comerciales nos permiten brindar tarifas muy competitivas en el mercado.</p>
                </div>               
                </Stack>
            </div>
        </Stack>
    </Box>
    );
};

export default OurFeatures;
