import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Resultadotransaccion from "./resultadoTransaccionDesktop";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom"

export default function Titlebar() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [resultadoTran, setResultadoTran] = useState([]);
  const params = useParams();
  
  let [searchParams, setSearchParams] = useSearchParams()
  const idT = searchParams.get("id")

  useEffect(() => {
    // 👇️ only runs once
    window.scrollTo(0, 0);
    fillResultadoTransaccion();
  }, []);

  const fillResultadoTransaccion = async () => {
    const url =
      "https://iberolunatravel.online/wse/api/ResultadoTransaccion?idTransaccion=" + idT;
    axios
      .get(url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setResultadoTran(response.data);
        const elementsList = Array.from(response.data);
        // console.log(elementsList);
      });
  };

  return (
    <>
    
      <Resultadotransaccion matches={matches} resultado={resultadoTran} />
    </>
  );
}
