import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import "../../App.css";
import { Container, Typography, Box, Stack, Grid, Button } from "@mui/material";
import theme from "../../styles/theme";
import { Fragment, useEffect, useState } from "react";
import Footer from "../footer";
import Appbar from "../appbar";
import { UIProvider } from "../../context/ui";
import AppDrawer from "../drawer";
import Informationcircuitsresults from "./informationcircuitsresults";
import { Colors } from "../../styles/theme";
import Titlebar from "./titlebar";
import Circuits from "./circuits";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { AppTitleBlue, AppTitleOrange } from "../../styles/app";
import { AppbarHeaderImage } from "../../styles/appbar";
import Products from "../products";

function Circuitsdetaildesktop(props) {
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [flagDialogMensaje, setFlagDialogMensaje] = useState(false);
  const [mensajeDialog, setMensajeDialog] = useState("");
  const [optionCircuit, setOptionCircuit] = useState(0);
  const [precioDesde, setPrecioDesde] = useState(0);
  const [precioHasta, setPrecioHasta] = useState(0);
  const [circuitsResults, setCircuitsResults] = useState([]);
  const [ciudadesFiltro, setCiudadesFiltro] = useState("");
  const [paisesFiltro, setPaisesFiltro] = useState("");
  const [inicioFiltro, setInicioFiltro] = useState("");
  const [diasFiltro, setDiasFiltro] = useState("");
  const [finFiltro, setFinFiltro] = useState("");
  const [habitacionFiltro, setHabitacionFiltro] = useState("");
  const [ordenFiltro, setOrdenFiltro] = useState("");
  const [serieFiltro, setSerieFiltro] = useState("");
  const [totalCircuitos, setTotalCircuitos] = useState("");
  const [totalPagination, setTotalPagination] = useState(10);
  const [indexPagination, setIndexPagination] = useState(1);
  const [indexFiltro, setIndexFiltro] = useState(0);
  const [fechaFiltro, setFechaFiltro] = useState("");
  const [precioFiltro, setPrecioFiltro] = useState("");
  const [nombreCircuito, setNombreCircuito] = useState("");
  const [descripcionFolleto, setDescripcionFolleto] = useState("");
  const [flagWait, setFlagWait] = useState(false);
  const [flagFirst, setFlagFirst] = useState(true);
  const [orden, setOrden] = useState("ASC");
  const [ordenCampo, setOrdenCampo] = useState("Precio");
  const [tabFecha, setTabFecha] = useState("*");
  const [circuitos, setCircuitos] = useState([]);

  const searchCircuits = (
    feccir,
    ciudades,
    paises,
    inicio,
    fin,
    habitacion,
    precioRango,
    serie = "",
    orden = "PrecioASC",
    index = indexPagination,
    nombre = "",
    dias = "0"
  ) => {
    // console.log("paises");
    // console.log(props.paisesCriterio);
    // console.log(feccir);
    // console.log(feccir);
    
    setTabFecha(feccir.split('-')[0] + feccir.split('-')[1].padStart(2,'0'));
    setCiudadesFiltro(ciudades);
    setPaisesFiltro(paises);
    setInicioFiltro(inicio);
    setFinFiltro(fin);
    setHabitacionFiltro(habitacion);
    setOrdenFiltro(orden);
    setIndexFiltro(index);
    setFechaFiltro(feccir);
    setPrecioFiltro(precioRango);
    setTotalCircuitos("");
    setSerieFiltro(serie);
    setNombreCircuito(nombre);
    setDiasFiltro(dias);
    SearchHotelsAPI(
      ciudades,
      paises,
      inicio,
      fin,
      habitacion,
      orden,
      index,
      feccir,
      precioRango,
      serie,
      nombre,
      dias
    );
  };

  const SearchHotelsAPI = (
    ciudades,
    paises,
    inicio,
    fin,
    habitacion,
    orden,
    index,
    feccir,
    precioRango,
    serie,
    nombre = "",
    dias = ""
  ) => {
    window.scrollTo(0, 0);
    /*  setOpen(true);
    setFirstPagination(1);
    setIndexPagination(1);
    setFlagFilter(true); */
    // console.log("seire-serie");
    // console.log(serie);

    // var porpagina = 9;
    // if (!props.matches) {
    //   porpagina = 15;
    // }
    var porpagina = 9;
    setFlagWait(true);
    const url =
      "https://iberolunatravel.online/wse/api/SearchCircuits?ciudades=" +
      ciudades +
      "&paises=" +
      paises +
      "&ciudadinicio=" +
      inicio +
      "&ciudadfin=" +
      fin +
      "&habitacion=" +
      habitacion +
      "&paginacion=" +
      index +
      "&porpagina=" +
      porpagina +
      "&orden=" +
      orden +
      "&feccir=" +
      feccir +
      "&preciorango=" +
      precioRango +
      "&tipo=" +
      props.tipo +
      "&serie=" +
      serie +
      "&nombre=" +
      nombre +
      "&dias=" +
      dias;
    axios
      .get(url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response.data.length > 0) {
          FillResults(response.data);
        } else {
          setMensajeDialog(
            "No existen circuitos con las condiciones indicadas."
          );
          setFlagDialogMensaje(true);
        }
        setFlagWait(false);
      })
      .catch((error) => {
        setFlagWait(false);
      });
  };

  const handleCloseDialogMensaje = (event, reason) => {
    if (reason !== "backdropClick") {
      setFlagDialogMensaje(false);
    }
  };

  const FillResults = (rs) => {
    // console.log(rs[0]);
    setCircuitsResults(rs);
    setTotalCircuitos(rs[0].TotalCircuitos + " Circuitos");
    setPrecioDesde(rs[0].PrecioMin);
    setPrecioHasta(rs[0].PrecioMax);
    setIndexPagination(rs[0].IndexPaginacion);
    setTotalPagination(rs[0].TotalPaginas);
    // console.log(rs);
  };

  const applyOrder = (order = "PrecioASC") => {
    setOrden(order.substring(order.length - 3, order.length));
    setOrdenCampo(order.substring(0, order.length - 3));
    searchCircuits(
      fechaFiltro,
      ciudadesFiltro,
      paisesFiltro,
      inicioFiltro,
      finFiltro,
      habitacionFiltro,
      precioFiltro,
      serieFiltro,
      order,
      1,
      nombreCircuito,
      diasFiltro
    );
  };

  const ApplyPaging = (event, value) => {
    searchCircuits(
      fechaFiltro,
      ciudadesFiltro,
      paisesFiltro,
      inicioFiltro,
      finFiltro,
      habitacionFiltro,
      precioFiltro,
      serieFiltro,
      ordenFiltro,
      value,
      nombreCircuito,
      diasFiltro
    );
  };

  useEffect(() => {
    if (props.tipo === "Destino") {
      var desc = "";
      switch (props.codfolleto) {
        case "Europa":
          desc = "Europa";
          break;
        case "USA":
          desc = "Estados Unidos y Canadá";
          break;
        case "ORIENTE":
          desc = "Turquía y Oriente Medio";
          break;
        case "ASIA":
          desc = "Asia y Oceanía";
          break;
        case "MEXICO":
          desc = "México y Cuba";
          break;
        case "EUROPAFLUVIAL":
          desc = "Cruceros Fluviales";
          break;
        default:
          desc = props.codfolleto;
      }

      setDescripcionFolleto(desc);
    } else {
      // console.log("props.folletosData");
      // console.log(props.folletosData);
      let obj = props.folletosData.find(
        (data) => data.ColorViaje === props.codfolleto
      );

      if (obj !== undefined) {
        setDescripcionFolleto(obj.DescripcionFolleto);
      }
      /* if (flagFirst && props.fecha === undefined) {
        setFlagFirst(false);
        applyOrder("PrecioASC");
      } */
    }
  }, [circuitsResults]);

  // function capitalizeFirstLetter(text) {
  //   return text.charAt(0).toUpperCase() + text.slice(1);
  // }

  // function toParagraphCase(text) {
  //  // Split the text into sentences using a regular expression to match sentence-ending punctuation or new lines
  // const sentences = text.match(/[^.!?]+[.!?]*\s*/g);
  
  // // If no sentences are found, return the original text in proper case
  // if (!sentences) return capitalizeFirstLetter(text.toLowerCase());

  // // Process each sentence
  // const paragraphCaseText = sentences
  //   .map(sentence => {
  //     // Trim whitespace from the sentence and convert to lowercase
  //     const trimmed = sentence.trim().toLowerCase();
  //     // Capitalize the first letter
  //     return capitalizeFirstLetter(trimmed);
  //   })
  //   // Join the sentences back together with a space
  //   .join(' ');

  // return paragraphCaseText;
  // }

  const fillCircuitos = async () => {
    setFlagWait(true);
    // const url =
    //   "https://iberolunatravel.online/wse/api/SearchCircuitsHome?tipo=Circuitos";
    let url = "../../data/circuitosHome.json";
    if (props.tipo === "Destino") {
      switch (props.codfolleto.toLowerCase()) {
        case "2025":
          url = "../../data/circuitosrecomendados2025.json";
          break;
        case "europa":
          url = "../../data/circuitosrecomendadoseuropa.json";
          break;
        case "usa":
          url = "../../data/circuitosrecomendadosestadosunidocanada.json";
          break;
        case "oriente":
          url = "../../data/circuitosrecomendadosturquiaoriente.json";
          break;
        case "asia":
          url = "../../data/circuitosrecomendadosasiaoceania.json";
          break;
        case "mexico":
          url = "../../data/circuitosrecomendadosmexicocuba.json";
          break;
        case "europafluvial":
          url = "../../data/circuitosrecomendadoscrucerosfluviales.json";
          break;
        default:
          url = "../../data/circuitosrecomendadosgeneral.json";
      } } else {
      url = "../../data/circuitosrecomendadosGeneral.json";
      }

      // console.log(url);
      // console.log(props.codfolleto);
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    setCircuitos(resp.data);
  };

  useEffect(() => {
    document.title = "Viajes Iberoluna - Búsqueda";
    fillCircuitos();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          background: "#fff",
          padding: "0px !important",
        }}
      >
        <Stack>
          <UIProvider>
            <Appbar />
            {/* <Titlebar></Titlebar>             */}
            <Stack alignItems="center">
              <AppTitleOrange
                variant={!props.matches ? "h2" : "h4"}
                sx={{
                  textAlign: "center",
                  marginTop: "30px",
                  marginBottom: "15px",
                }}
              >
                {props.tipo === "Ofertas"
                  ? "Buscador de Ofertas"
                  : props.tipo === "Circuitos"
                  ? "Buscador de Circuitos"
                  : props.tipo === "Destino"
                  ? "Destino: " + descripcionFolleto
                  : "Catálogo: " + descripcionFolleto}
              </AppTitleOrange>
              {props.matches ? (
                <img
                  loading="lazy"
                  width="100%"
                  src={"../../images/IncluyeMovil.jpg"}
                  srcSet={"../../images/IncluyeMovil.jpg"}
                  alt="Incluye Circuito"
                />
              ) : (
                <img
                  loading="lazy"
                  width="100%"
                  src={"../../images/incluye.jpg"}
                  srcSet={"../../images/incluye.jpg"}
                  alt="Incluye Circuito"
                />
              )}
              <br></br>
            </Stack>
            <Stack
              sx={{
                background: "#fff",
                borderColor: Colors.blue_ibero,
                padding: "15px",
                borderRadius: "15px",
                borderStyle: "solid",
                borderWidth: "1px",
                m: "15px",
              }}
            >
              <Stack
                direction={!props.matches ? "row" : "column"}
                spacing={2}
                justifyContent="space-between"
              >
                {props.ciudadesData.length > 0 &&
                props.paisesData.length > 0 ? (
                  <Informationcircuitsresults
                    ciudadesData={props.ciudadesData}
                    paisesData={props.paisesData}
                    folletosData={props.folletosData}
                    precioDesde={precioDesde}
                    precioHasta={precioHasta}
                    onClick={searchCircuits}
                    matches={props.matches}
                    flagWait={flagWait}
                    tipo={props.tipo}
                    codfolleto={props.codfolleto}
                    fecha={props.fecha}
                    paisesCriterio={props.paisesCriterio}
                    ciudadesCriterio={props.ciudadesCriterio}
                    dias={props.dias}
                  />
                ) : (
                  <>
                    <Grid
                      item
                      xs={1}
                      container
                      spacing={0}
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      style={{ minHeight: "50vh" }}
                    >
                      <br></br>
                      <CircularProgress />
                      <br></br>
                    </Grid>
                  </>
                )}
                {/* <Divider orientation="vertical" variant="middle" flexItem /> */}
                <Box sx={{ width: "82%" }}>
                {/* {totalCircuitos !== "" ? (
                  <> */}
                    {/* <Stack> */}
                    {flagWait && (
                      <Grid
                    item
                    xs={12}
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    // style={{ minHeight: "50vh" }}
                  >
                    <br></br>
                    <CircularProgress />
                    <br></br>
                  </Grid>
                    )}
                    {indexPagination === 1 && circuitos.length > 0 &&  props.tipo === "Destino" &&
                      <>
                        <Box
                          display="flex"
                          justifyContent="center"
                          textAlign="center"
                          sx={{ p: 2 }}
                        >
                          <AppTitleBlue
                            variant={!props.matches ? "h3" : "h4"}
                            color="comon.white"
                            textAlign="center"
                          >
                            {/* Circuitos recomendados de {toParagraphCase(descripcionFolleto)} */}
                            Circuitos recomendados
                          </AppTitleBlue>
                          {/* <Typography variant="h4" color="comon.white">Circuitos Especiales</Typography> */}
                        </Box>     
                        {/* <Box
                          sx={{ display: "flex", p: 2 }}
                          alignItems="center"
                          justifyContent="center"
                        >
                        </Box>   */}
                          <Circuits
                                  circuitsResultsX={circuitos}
                                  tipo={props.tipo}
                                  matches={props.matches}
                                  tabFecha={tabFecha}
                                  recomendados={true}
                                ></Circuits>  
                      <br></br>
                      <Divider orientation="vertical" variant="middle" flexItem sx={{backgroundColor: Colors.orange_ibero, height: "1px"}} />
                      <br></br>
                  </> 
                  }
                  
        {totalCircuitos !== "" ? (
          <>
          <Stack>
                      <Titlebar
                        applyOrder={applyOrder}
                        totalCircuitos={totalCircuitos}
                        matches={props.matches}
                        tipo={props.tipo}
                        orden={orden}
                        ordenCampo={ordenCampo}
                      ></Titlebar>
                      <br></br>
                      <Circuits
                        circuitsResultsX={circuitsResults}
                        tipo={props.tipo}
                        matches={props.matches}
                        tabFecha={tabFecha}
                        recomendados={false}
                      ></Circuits>
                    </Stack>
                  </>
                ) : (
                  <>
                  {flagWait &&  (
                  <Grid
                    item
                    xs={12}
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: "50vh" }}
                  >
                    <br></br>
                    <CircularProgress />
                    <br></br>
                  </Grid>
                  )}
                </>
                )}
                </Box>
              </Stack>
              {totalCircuitos !== "" ? (
                <>
                  <Stack
                    /*  spacing={2}
                justifyContent="space-between" */
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      background: "lightgray",
                      mt: "10px",
                      padding: "15px",
                      borderRadius: "15px",
                    }}
                  >
                    <Grid item xs={12}>
                      <Pagination
                        variant="outlined"
                        color={"primary"}
                        count={totalPagination}
                        size={props.matches ? "small" : "medium"}
                        //className={classes.pagination}
                        page={indexPagination}
                        onChange={ApplyPaging}
                      />
                    </Grid>
                  </Stack>
                </>
              ) : (
                <></>
              )}
            </Stack>
            <AppDrawer />
          </UIProvider>
        </Stack>
        <Footer />
      </Container>

      <Dialog
        fullScreen={fullScreen}
        open={flagDialogMensaje}
        onClose={handleCloseDialogMensaje}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <AppbarHeaderImage
            src="/images/branding/LogoIberoluna.png"
            alt="Viajes Iberoluna"
            title="Viajes Iberoluna"
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              <Box
                fontWeight="Medium"
                //display="inline"
                sx={{ width: "100%" }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {mensajeDialog}
              </Box>{" "}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogMensaje} autoFocus>
            <CloseOutlinedIcon
              fontSize="large"
              sx={{ color: "gray" }}
            ></CloseOutlinedIcon>
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default Circuitsdetaildesktop;
