import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import AppbarDesktop from "./appbarDesktop";
import AppbarMobile from "./appbarMobile";
import { Fragment } from "react";
import { Link } from "react-router-dom";

export default function Appbar() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));
  const bannerStyle = () => {
    return {
      boxShadow: "1px 2px 9px Gray",
      marginTop: "5px"
    };
  };

  function ParteSuperior() {
    let content = [];
    content.push(
      <Fragment>
        {" "}
        <Link
          to={"/results/Circuitos/All/2024-07/All/All/All"}
          style={{ textDecoration: "none" }}
        >
          {matches ? (
            <>
              <img
                loading="lazy"
                width="100%"
                src={"../../images/BannerPreVentaMobile.png"}
                srcSet={"../../images/BannerPreVentaMobile.png"}
                alt="PreVenta Europamundo"
                style={{ boxShadow: "1px 2px 9px Gray", marginTop: "5px" }}
              />
            </>
          ) : (
            <>
              <img
                loading="lazy"
                width="100%"
                src={"../../images/BannerPreVenta.jpg"}
                srcSet={"../../images/BannerPreVenta.jpg"}
                alt="PreVenta Europamundo"
                style={bannerStyle()}
              />
            </>
          )}
        </Link>       
      </Fragment>
    );
    return content;
  }
  return (
    <>
      {matches ? <AppbarMobile matches={matches}/> : <AppbarDesktop matches={matches}/>}
      {/* {ParteSuperior()} */}
    </>
  );
}
