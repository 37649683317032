import {
  Dialog,
  DialogActions,
  Divider,
  Fab,
  ThemeProvider,
} from "@mui/material";
import "../../App.css";
import { Container, Box, Stack, Button } from "@mui/material";
import theme from "../../styles/theme";
import Appbar from "../appbar";
import { Fragment, useEffect, useState } from "react";
import Footer from "../footer";
import { UIProvider } from "../../context/ui";
import AppDrawer from "../drawer";
import Informationcircuitsdetail from "./informationcircuitsdetail";
import InformationcircuitsdetailMobile from "./informationcircuitsdetailMobile";
import Itinerary from "./itinerary";
import { Colors } from "../../styles/theme";
import Titlebar from "./titlebar";
import Fichacircuitdetail from "./fichacircuitdetail";
import Imagescircuitsdetail from "./imagescircuitdetail";
import Hotelescircuitsdetail from "./hotelesprevistosdetail";
import Cotizacioncircuitdetail from "./cotizacioncircuitdetail";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import ItinerarioFechas from "./itinerariofechas"
import axios from "axios";
import { useRef } from "react";

function Circuitsdetaildesktop(props) {
  const dialogRef = useRef(null);
  const [optionCircuit, setOptionCircuit] = useState(0);
  const [cardNumber, setCardNumber] = useState(1);
  const [openIncluye, setOpenIncluye] = useState(false);
  const [crit, setCrit] = useState({});

  const setOption = (option) => {
    setOptionCircuit(option);
  };

  const handleOpenDialogIncluye = () => {
    if (dialogRef.current) {
      // move the scroll to the top
      dialogRef.current.content.scrollTo(0, 0);
    }
  };

  const ActualizaCardNumber = (cardN) => {
    setCardNumber(cardN);
    //console.log(cardN);
  };

  const muestraIncluye = (crit) => {
   setCrit(crit);
    setOpenIncluye(true);
  };

  const handleCloseDialogIncluye = () => {
    setOpenIncluye(false);
  };

  const cotizaviaje = () => {
    window.scrollTo(0, 0);
    setOptionCircuit(99);
  };

  const Contenido = () => {
    let content = [];
    content.push(
      <>
        <Stack>
          {optionCircuit === 1 ? (
            <Imagescircuitsdetail
              imagenes={props.imagenes}
              matches={props.matches}
            ></Imagescircuitsdetail>
          ) : optionCircuit === 2 ? (
            <Hotelescircuitsdetail
              hoteles={props.hoteles}
            ></Hotelescircuitsdetail>
          ) : optionCircuit === 3 ? (
            !props.matches ? (
              <Fragment>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  sx={{ paddingBottom: "40px" }}
                >
                  <Box sx={{ textAlign: "justify" }}>
                    <Itinerary
                      itinerario={props.itinerario}
                      matches={props.matches}
                      tipo={"DETALLADO"}
                      flagDialog={false}
                    />
                  </Box>
                </Stack>
              </Fragment>
            ) : (
              <Fragment>
                <Stack
                  spacing={2}
                  justifyContent="space-between"
                  sx={{ paddingBottom: "40px" }}
                >
                  <Box sx={{ textAlign: "justify" }}>
                    <Itinerary
                      itinerario={props.itinerario}
                      matches={props.matches}
                      tipo={"DETALLADO"}
                      flagDialog={false}
                    />
                  </Box>
                </Stack>
              </Fragment>
            )
          ) : optionCircuit === 99 ? (
            <Cotizacioncircuitdetail
              logid={props.logid}
              cardNumber={cardNumber}
              fechas={props.fechas}
              fechas1={props.fechas1}
              fechas2={props.fechas2}
              fechas3={props.fechas3}
              fechas4={props.fechas4}
              fechas5={props.fechas5}
              fechas6={props.fechas6}
              fechas7={props.fechas7}
              fechas8={props.fechas8}
              fechas9={props.fechas9}
              fechas10={props.fechas10}
              fechas11={props.fechas11}
              meses={props.meses}
              lineasaereas={props.lineasaereas}
              circuito={props.circuitoData}
              matches={props.matches}
              actualizaCardNumber={ActualizaCardNumber}
              muestraIncluye={muestraIncluye}
              tabFecha={props.tabFecha}
            ></Cotizacioncircuitdetail>
          ) : (
            <Fragment>
              <Fichacircuitdetail
                imagenes={props.imagenes}
                circuitoIncluye={props.circuitoIncluye}
                imagenmapa={props.circuitoData.IMAGENMAPA}
                circuito={props.circuitoData}
                matches={props.matches}
                flagWait={props.flagWait}
                flagDialog={false}
              ></Fichacircuitdetail>

              <Itinerary
                itinerario={props.itinerario}
                matches={props.matches}
                tipo={"RESUMIDO"}
                flagWait={props.flagWait}
                flagDialog={false}
              />
            </Fragment>
          )}
        </Stack>
      </>
    );
    return content;
  };

  useEffect(() => {
    //setOptionCircuit(option)
    // console.log(optionCircuit);
  }, [optionCircuit]);

  useEffect(() => {
    document.title = "Viajes Iberoluna - Detalle Circuito";
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          background: "#fff",
          padding: "0px !important",
        }}
      >
        <Fab
          variant="extended"
          size="small"
          color="primary"
          aria-label="add"
          onClick={cotizaviaje}
          sx={{
            position: "fixed",
            bottom: "100px",
            right: "25px",
            backgroundColor: Colors.orange_ibero
          }}
        >
          <CalculateOutlinedIcon sx={{paddingRight: "5px"}}/>
         {'  '} Cotice su viaje
        </Fab>
        <Stack>
          <UIProvider>
            <Appbar />

            {/* <Titlebar></Titlebar>             */}
            <Stack
              sx={
                !props.matches
                  ? {
                      background: "#fff",
                      borderColor: Colors.blue_ibero,
                      padding: "5px",
                      borderRadius: "15px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      m: "15px",
                    }
                  : {
                      background: "#fff",
                      padding: "0px",
                      borderStyle: "none",
                      m: "5px",
                    }
              }
            >
              <Titlebar
                circuitoData={props.circuitoData}
                onClick={setOption}
                matches={props.matches}
                tipo={props.tipo}
              ></Titlebar>
              {!props.matches ? (
                <Fragment>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <Box sx={{ width: "160px" }}>
                      <Informationcircuitsdetail
                        onClick={setOption}
                        pdf={props.circuitoData.PDFDOLARES}
                        word={props.circuitoData.WORDDOLARES}
                      />
                    </Box>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Box sx={{ width: "1250px" }}>{Contenido()}</Box>
                  </Stack>
                </Fragment>
              ) : (
                <Fragment>
                  <Stack spacing={2} justifyContent="space-between">
                    <InformationcircuitsdetailMobile
                      onClick={setOption}
                      pdf={props.circuitoData.PDFDOLARES}
                      word={props.circuitoData.WORDDOLARES}
                      sx={{ with: "100%" }}
                    />
                    <Divider variant="middle" flexItem />
                    {Contenido()}
                  </Stack>
                </Fragment>
              )}
              {/*      <Stack direction="row" spacing={2} justifyContent="space-between">
                {BarraMenu()}
                {Contenido()}
              </Stack> */}
            </Stack>
            <AppDrawer />
          </UIProvider>
        </Stack>
        <Footer />
        {/*
        AppBar
        Promotions
        creteriabox
        Products
        footer
        Searchbos
        appDrawer
        */}
      </Container>

      <Dialog
      ref={dialogRef}
      onEntered={handleOpenDialogIncluye}
        id={"dialogIti"}
        fullWidth={true}
        maxWidth={"lg"}
        open={openIncluye}
        onClose={handleCloseDialogIncluye}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      {/*   <Fichacircuitdetail
          imagenes={props.imagenes}
          circuitoIncluye={props.circuitoIncluye}
          imagenmapa={props.circuitoData.IMAGENMAPA}
          circuito={props.circuitoData}
          matches={props.matches}
          flagWait={props.flagWait}
          flagDialog={true}
        ></Fichacircuitdetail>
        <Itinerary
          itinerario={itinerarioFec}
          matches={props.matches}
          tipo={"RESUMIDO"}
          flagWait={props.flagWait}
          flagDialog={true}
        /> */}
        <ItinerarioFechas
         imagenes={props.imagenes}
         circuitoIncluye={props.circuitoIncluye}
         imagenmapa={props.circuitoData.IMAGENMAPA}
         circuito={props.circuitoData}
         matches={props.matches}
         flagWait={props.flagWait}
         flagDialog={true}
         crit={crit}
         tipo={"RESUMIDO"}
        />
        <DialogActions>
          <Button onClick={handleCloseDialogIncluye} autoFocus>
            <CloseOutlinedIcon
              fontSize="large"
              sx={{ color: "gray" }}
            ></CloseOutlinedIcon>
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default Circuitsdetaildesktop;
