import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import SalidasGrupalesDesktop from "./programasEspecialesDesktop";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function Titlebar() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [urlPDF, setUrlPdf] = useState("");
  const params = useParams();
  const url = params.url;

  useEffect(() => {
    // 👇️ only runs once
    window.scrollTo(0, 0)
    setUrlPdf(`\\contenidos\\salidasgrupales\\${url}`);
    // console.log(`\\contenidos\\salidasgrupales\\${url}`);
  }, []);

  return (
    <>
    <SalidasGrupalesDesktop url={urlPDF} matches={matches} />
    </>
  );
}
