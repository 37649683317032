import { Avatar, CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import {  CircuitImage, CircuitImageHome } from "../../../styles/circuit";
import { Colors } from "../../../styles/theme";
import useDialogModal from "../../../hooks/useDialogModal";
import MapView from "../../mapview";
import { useState } from "react";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

const options = {
  useGrouping: true,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};

const userLocale =
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language;

export default function CircuitMetaTitle({
  circuit,
  matches,
  tipo,
  opcionhome,
  ofertas,
  tabFecha,
  recomendados=false
}) {
  const [MapViewDialog, showMapViewDialog, closeMapView] =
    useDialogModal(MapView);
    const [flagLoad, setFlagLoad] = useState(false);

    const imageLoaded = () => {
      // counter.current += 1;
      // if (counter.current >= urls.length) {
      //   setLoading(false);
      // }
      setFlagLoad(true);
    }

  return (
    <>
      <Box
        sx={{
          position: "relative",
          bgcolor: tipo === "Ofertas" ? Colors.orange_light : "aliceblue",
        }}
      >
        <Link
          to={
            (tipo === "Ofertas" ? "/oferta/" : "/circuito/") + circuit.Id_Viaje + "/" + tabFecha
          }
          target="_blank"
        >
          {opcionhome === "resumido" ? 
        
           <CircuitImageHome src={circuit.URL_Imagen} alt={circuit.Nombre_Imagen} title={circuit.Nombre_Imagen}></CircuitImageHome>  
        : 
      
        <CircuitImage src={circuit.URL_Imagen} alt={circuit.Nombre_Imagen} title={circuit.Nombre_Imagen}></CircuitImage>}
        
        </Link>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            bgcolor: ofertas
              ? "rgba(241, 97, 34, 0.3)"
              : recomendados ? "rgba(241, 97, 34, 0.5)" : "rgba(14, 103, 162, 0.3)",            
            height: "40px",
            padding: "7px",
            color: "white",
            textShadow: recomendados ? "3px 3px 5px black" : "3px 3px 5px black",
            display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.30)" // Adding bottom shadow
          }}
        >
          {/* <CircuitMetaTitleWrapper> */}
          {/* <Typography variant="h5">{circuit.name}</Typography> */}
          <Typography
            variant={matches ? "h7" : "h6"}
            lineHeight={1}
            sx={{ width: "95%", textAlign: "center", verticalAlign: "middle" }}
          >
            {circuit.Nombre_Viaje}
          </Typography>
        </Box>
        {/* {!matches ? ( */}
        {true ? (
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              // bgcolor: "rgba(0, 0, 0, 0.54)",
              // bgcolor: "#0E67A2",
              // opacity: 0.7,
              color: "white",
              textShadow: "0px 2px 20px red",
              padding: "10px",
            }}
          >
          


            <Link
              style={{ textDecoration: "none" }}
              to={
                (tipo === "Ofertas" ? "/oferta/" : "/circuito/") +
                circuit.Id_Viaje  + "/" + tabFecha
              }
              target="_blank"
            >
              <Avatar
                sx={{
                  bgcolor: Colors.orange_ibero,
                  textAlign: "center",
                  fontSize: "12px",
                  padding: 0.8,
                }}
              >
                {circuit.Numero_Dias} días
              </Avatar>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              to={
                (tipo === "Ofertas" ? "/oferta/" : "/circuito/") +
                circuit.Id_Viaje + "/" + tabFecha
              }
              target="_blank"
            >
              <Avatar
                sx={{
                  bgcolor: Colors.blue_ibero,
                  textAlign: "center",
                  fontSize: "12px",
                  padding: 0.8,
                }}
              >
                {circuit.Numero_Ciudades} ciudades
              </Avatar>
            </Link>
          </Box>
        ) : (
          <>
            {ofertas ? (
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  // bgcolor: "rgba(0, 0, 0, 0.54)",
                  // bgcolor: "#0E67A2",
                  // opacity: 0.7,
                  color: "white",
                  textShadow: "0px 2px 20px red",
                  padding: "10px",
                }}
              >
                <Link
                  style={{ textDecoration: "none" }}
                  to={
                    (tipo === "Ofertas" ? "/oferta/" : "/circuito/") +
                    circuit.Id_Viaje  + "/" + tabFecha
                  }
                  target="_blank"
                >
                  <Avatar
                    sx={{
                      //bgcolor: Colors.blue_ibero,
                      textAlign: "center",
                      fontSize: "5px",
                      padding: 0.5,
                      cursor: "pointer",
                    }}
                  >
                    <img src="/images/Oferta.png"></img>
                  </Avatar>
                </Link>
              </Box>
            ) : (
              <></>
            )}
          </>
        )}
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            // bgcolor: "rgba(0, 0, 0, 0.54)",
            // bgcolor: "#0E67A2",
            // opacity: 0.7,
            color: "white",
            textShadow: "0px 2px 20px red",
            padding: "10px",
            textAlign: "right",
          }}
        >
          {/* {!matches ? ( */}
          {true ? (
            <>
            {recomendados && (
            <Link
              style={{ textDecoration: "none"}}
              to={
                (tipo === "Ofertas" ? "/oferta/" : "/circuito/") +
                circuit.Id_Viaje + "/" + tabFecha
              }
              target="_blank"
            >
              <Avatar
                sx={{
                  bgcolor: "red",
                  textAlign: "center",
                  fontSize: "12px",
                  padding: 0.8,
                  margingBotton: "5px"
                }}
              >
                <ThumbUpIcon sx={{color: Colors.white, fontSize: "25px"}}></ThumbUpIcon>
              </Avatar>
            </Link>
          )}
              {ofertas ? (
                <Link
                  style={{ textDecoration: "none" }}
                  to={
                    (tipo === "Ofertas" ? "/oferta/" : "/circuito/") +
                    circuit.Id_Viaje  + "/" + tabFecha
                  }
                  target="_blank"
                >
                  <Avatar
                    sx={{
                      //bgcolor: Colors.blue_ibero,
                      textAlign: "center",
                      fontSize: "5px",
                      padding: 0.5,
                      cursor: "pointer",
                    }}
                  >
                    <img src="/images/Oferta.png"></img>
                  </Avatar>
                </Link>
              ) : (
                <></>
              )}
              <Avatar
                sx={{
                  bgcolor: Colors.blue_ibero,
                  textAlign: "center",
                  fontSize: "12px",
                  padding: 0.8,
                  cursor: "pointer",
                }}
                onClick={() => showMapViewDialog(circuit.URL_Mapa)}
              >
                Ver Ruta
              </Avatar>
              <Link
                style={{ textDecoration: "none" }}
                to={
                  (tipo === "Ofertas" ? "/oferta/" : "/circuito/") +
                  circuit.Id_Viaje  + "/" + tabFecha
                }
                target="_blank"
              >
                {/* <Avatar
                  sx={{
                    bgcolor: Colors.orange_ibero,
                    textAlign: "center",
                    fontSize: "12px",
                    padding: 0.8,
                    cursor: "pointer",
                  }}
                >
                  Desde USD{" "}
                  {Number(circuit.Precio_Desde).toLocaleString(
                    userLocale.substring(0, 2),
                    options
                  )}
                </Avatar> */}
              </Link>
            </>
          ) : (
            <Typography
              variant={matches ? "h7" : "h6"}
              lineHeight={1}
              sx={{ fontWeight: "bolder" }}
            >
              Desde USD{" "}
              {Number(circuit.Precio_Desde).toLocaleString(
                userLocale.substring(0, 2),
                options
              )}
            </Typography>
          )}
        </Box>
        {/* </CircuitMetaTitleWrapper> */}
      </Box>

      <MapViewDialog product={circuit} />
    </>
  );
}
