import {
  Button,
  Grid,
  Paper,
  Table,
  Box,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import "../../App.css";
import { Container, Stack } from "@mui/material";
import theme from "../../styles/theme";
import Appbar from "../appbar";
import { Fragment, useEffect } from "react";
import Footer from "../footer";
import { UIProvider } from "../../context/ui";
import AppDrawer from "../drawer";
import { Colors } from "../../styles/theme";
import { AppTitleBlue, AppTitleOrange } from "../../styles/app";
import styled from "@emotion/styled";

function Circuitsdetaildesktop(props) {
  useEffect(() => {
    document.title = "Viajes Iberoluna - Condicione Generales";
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: Colors.blue_ibero,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "8px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          background: "#fff",
          padding: "0px !important",
        }}
      >
        <Stack  sx={{         
          height: "1500px",
        }}>
          <UIProvider>
            <Appbar />
            <Stack>
              <Stack
              alignItems="center"
                direction={!props.matches ? "row" : "column"}
                spacing={2}
                justifyContent="space-between"
              >              
                <Stack 
                 alignItems="center"  // This centers vertically within the stack
  justifyContent="center" // Add this to center horizontally
  sx={{ width: '100%', marginTop: "7px" }}
  >               
                  {/* <AppTitleOrange
                    variant={!props.matches ? "h3" : "h4"}
                    sx={{ textAlign: "center",marginTop: "30px"  }}
                  >
                    CONDICIONES GENERALES VIAJES IBEROLUNA S.A.S.
                  </AppTitleOrange>
                  <AppTitleBlue
                    variant={!props.matches ? "h5" : "h6"}
                    sx={{ textAlign: "center", marginBottom: "30px" }}
                  >
                    Nit: 830008421-5. Registro Nacional de Turismo número 36964
                  </AppTitleBlue> */}
                  {/* <Stack
                    sx={{
                      background: "#fff",
                      borderColor: Colors.blue_ibero,
                      padding: "15px",
                      borderRadius: "15px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      with: "100%",
                      m: "15px"
                    }}
                  > */}
                  <iframe
    src={"https://viajesiberoluna.com/" + props.cont}
    width="1270px"
    id="content2"
    frameborder="0"
    height="800px"
  ></iframe>
            
                  {/* </Stack> */}
                </Stack>
              </Stack>
            </Stack>
            <AppDrawer />
          </UIProvider>
        </Stack>
        {/* <Footer /> */}
      </Container>
    </ThemeProvider>
  );
}

export default Circuitsdetaildesktop;
