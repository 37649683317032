import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import CircuitsdetailDesktop from "../circuitsquery/circuitsdetailDesktop";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//import AppbarMobile from "./titleMobile";

export default function Titlebar(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [lineasAereas, setLineasAereas] = useState([]);
  const [circuitoData, setCircuitoData] = useState([]);
  const [circuitoIncluye, setCircuitoIncluye] = useState([]);
  const [circuitoItinerario, setCircuitoItinerario] = useState([]);
  const [circuitoHoteles, setCircuitoHoteles] = useState([]);
  const [imagenes, setImagenes] = useState([]);
  
  const [flag, setFlag] = useState(false);

  const [flagWait, setFlagWait] = useState(false);

  const [idV, setidV] = useState("");

  useEffect(() => {    
    window.scrollTo(0, 0);  
    setFlag(true);
  }, []);

  const fillData = (idviaje,iti) => {
    //console.log(idviaje);
    setidV(idviaje);
    setCircuitoIncluye(iti);
    fillViaje(idviaje);
    fillIncluye(idviaje);

    var videos = [];
    iti.forEach(function (entry) {
      // console.log(entry);
      if (entry.VIDEO !== "") {
        videos.push({
          original: entry.VIDEO,
          thumbnail: entry.VIDEO,
        });
      }
    });

    setCircuitoItinerario(iti);
    setFlagWait(false);

    //fillItinerario(idviaje);
    fillHoteles(idviaje);
  }

  const fillItinerario = async (idV) => {
    setFlagWait(true);
    const url = "https://iberolunatravel.online/wse/api/getitinerariocircuito?id=" + idV + "";
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    var videos = [];
    resp.data.forEach(function (entry) {
      // console.log(entry);
      if (entry.VIDEO !== "") {
        videos.push({
          original: entry.VIDEO,
          thumbnail: entry.VIDEO,
        });
      }
    });

    setCircuitoItinerario(resp.data);
    setFlagWait(false);
  };

  const fillIncluye = async (idV) => {
    const url = "https://iberolunatravel.online/wse/api/getincluyecircuito?id=" + idV + "";
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    setCircuitoIncluye(resp.data);
  };

  const fillHoteles = async (idV) => {
    const url = "https://iberolunatravel.online/wse/api/gethotelescircuito?id=" + idV + "";
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Headers": "Content-Type, application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE"
      },
    });

    setCircuitoHoteles(resp.data);
  };

  const fillViaje = async (idV) => {
    const url = "https://iberolunatravel.online/wse/api/getcircuito?id=" + idV + "";
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    //var images = resp.data.IMAGENES.split("ö");

    var images = [];
    resp.data.IMAGENES.split("ö").forEach(function (entry) {
      if (entry !== "") {
        images.push({
          original: entry,
          thumbnail: entry,
        });
      }
    });
    setImagenes(images);
    setCircuitoData(resp.data);
  };

  return (
    <>
      {matches ? (
        <CircuitsdetailDesktop key={"CD"}       
          circuitoData={circuitoData}
          circuitoIncluye={circuitoIncluye}
          imagenes={imagenes}
          matches={matches}
          itinerario={circuitoItinerario}
          hoteles={circuitoHoteles}
          flagWait={flagWait}
          fillData={fillData}
          tipo={props.tipo}
        />
      ) : (
        <CircuitsdetailDesktop key={"CD-C"}      
          circuitoData={circuitoData}
          circuitoIncluye={circuitoIncluye}
          matches={matches}
          imagenes={imagenes}
          itinerario={circuitoItinerario}
          hoteles={circuitoHoteles}
          lineasaereas={lineasAereas}
          flagWait={flagWait}
          fillData={fillData}
          tipo={props.tipo}
        />
      )}
    </>
  );
}
