import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "../components/Button";
import Typography from "../components/Typography";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Colors } from "../../../../../styles/theme";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
  minwidth: "180px",
};

const number = {
  fontSize: 32,
  fontFamily: "default",
  color: "secondary.main",
  fontWeight: "medium",
};

const image = {
  height: 25,
  my: 1.5,
  color: Colors.blue_ibero,
};

function ProductHowItWorks() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", bgcolor: "secondary.light", overflow: "hidden" }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src="/Images/productCurvyLines.png"
          alt="curvy lines"
          sx={{
            pointerEvents: "none",
            position: "absolute",
            top: -180,
            opacity: 0.7,
          }}
        />
        <Typography
          variant="h4"
          marked="center"
          component="h2"
          align="center"
          sx={{ mb: 8 }}
        >
          Calendario de salidas
        </Typography>
        <div >
          <Grid container spacing={1}  sx={{minWidth: '800px'}}>                           
            <Grid item xs={6} md={3}>
              <Box sx={item}>
                <Box sx={number}>Agosto</Box>
                <Box sx={image}>
                  <CalendarMonthIcon></CalendarMonthIcon>
                </Box>
                <Typography variant="h5" align="center">
                  21 agosto
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <Box sx={item}>
                <Box sx={number}>Septiembre</Box>
                <Box sx={image}>
                  <CalendarMonthIcon></CalendarMonthIcon>
                </Box>
                <Typography variant="h5" align="center">
                  25 Septiembre
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <Box sx={item}>
                <Box sx={number}>Octubre</Box>
                <Box sx={image}>
                  <CalendarMonthIcon></CalendarMonthIcon>
                </Box>
                <Typography variant="h5" align="center">
                  09 octubre
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <Box sx={item}>
                <Box sx={number}>Diciembre</Box>
                <Box sx={image}>
                  <CalendarMonthIcon></CalendarMonthIcon>
                </Box>
                <Typography variant="h5" align="center">
                  18 diciembre
                </Typography>
              </Box>
            </Grid>
          </Grid>

          {/* <Grid container spacing={4} sx={{ marginTop: "20px" }}>                     
            <Grid item xs={12} md={6}>
              <Box sx={item}>
                <Box sx={number}>Octubre</Box>
                <Box sx={image}>
                  <CalendarMonthIcon></CalendarMonthIcon>
                </Box>
                <Typography variant="h5" align="center">
                  09 octubre
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={item}>
                <Box sx={number}>Diciembre</Box>
                <Box sx={image}>
                  <CalendarMonthIcon></CalendarMonthIcon>
                </Box>
                <Typography variant="h5" align="center">
                  18 diciembre
                </Typography>
              </Box>
            </Grid>
          </Grid> */}
        </div>
        {/* <Button
          color="secondary"
          size="large"
          variant="contained"
          component="a"
          href="/premium-themes/onepirate/sign-up/"
          sx={{ mt: 8 }}
        >
          Get started
        </Button> */}
      </Container>
    </Box>
  );
}

export default ProductHowItWorks;
