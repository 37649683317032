import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Button } from "@mui/material";
import { promotions } from "../../data/promotions";
import { ImagePromotionCarousel } from "../../styles/promotions";
import { Link } from "react-router-dom";

export default function Example(props) {
  var items = [
    {
      name: "Random Name #1",
      description: "Probably the most random thing you have ever seen!",
    },
    {
      name: "Random Name #2",
      description: "Hello World!",
    },
  ];

  return (
    <Carousel
      autoPlay
      // stopAutoPlayOnHover
      navButtonsAlwaysVisible
      // indicators={true}
      swipe={false}
      // animation="slide"
    >
      {promotions.map((item, i) => (
        <Link key={i} to={item.url} style={{ textDecoration: "none" }}>
          <ImagePromotionCarousel key={i} src={item.image} />
        </Link>
      ))}
    </Carousel>
  );
}

function Item(props) {
  return (
    <Paper>
      <h2>{props.item.name}</h2>
      <p>{props.item.description}</p>

      <Button className="CheckButton">Check it out!</Button>
    </Paper>
  );
}
