import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardMedia, CardContent, Typography, Button, Box, ThemeProvider, Container, Stack, Grid } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { UIProvider } from '../../context/ui';
import { useTheme } from '@emotion/react';
import theme, { Colors } from "../../styles/theme";
import Appbar from '../appbar';
import { AppTitleOrange } from '../../styles/app';
import { WhatsAppWidget } from 'react-whatsapp-widget';

const StyledCard = styled(Card)(({ theme }) => ({
    position: 'relative',
    maxWidth: 500,
    borderRadius: 10,
    overflow: 'hidden',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s',
    '&:hover': {
        transform: 'translateY(-10px)',
    },
}));

const GradientBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    width: '100%',
    padding: theme.spacing(1),
    background: 'linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4))',
    color: '#fff',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
}));

const InfoBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(1),
}));

const InfoItem = styled(Box)(({ theme }) => ({
    background: 'rgba(255, 255, 255, 0.5)',
    padding: theme.spacing(1),
    borderRadius: '50%',
    textAlign: 'center',
    color: '#fff',
    minWidth: '85px', // Ensuring the circles are uniform in size
}));

const StyledButton = styled(Button)(({ theme }) => ({
    background: '#007bff',
    color: '#fff',
    padding: theme.spacing(1, 2),
    borderRadius: 20,
    textDecoration: 'none',
    transition: 'background 0.3s',
    '&:hover': {
        background: '#0056b3',
    },
    minHeight: 40,
}));

const TravelCard = () => {
    const [data, setData] = useState([]);
    // const theme = useTheme();

    const filldata = async () => {    
        const url = "../../data/microsite/demo.json";
        const resp = await axios.get(url, {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        });
        if (Array.isArray(resp.data.circuits)) {
            setData(resp.data.circuits);
        } else {
            console.error('Error: response data is not an array');
        }
      };

      useEffect(() => {
        // 👇️ only runs once
        document.title = "Felipe Tours";   
        window.scrollTo(0, 0);
       filldata();
      }, []);

    return (
        <Fragment>
        {/* <ThemeProvider theme={theme}> */}
          {/* <Container
            maxWidth="lg"
            sx={{
              background: "#eaeefc",
              padding: "0px !important",
            }}
          > */}
            <Stack>
              <UIProvider >
              <div style={{ backgroundImage: "linear-gradient(#0E67A2, #fff)"}}>
              <div style={{position: "relative",
    width: "100%",
    height: "180px", /* Adjust height as needed */
    background: "#ffffff", /* Adjust colors as needed */
    borderRadius: "0 0 50% 50%",
    overflow: "hidden"}}>
            <img 
                src="/images/branding/LogoIberoluna.png" // Replace with your actual logo path
                alt="Planes Turisticos"
                style={{position: "absolute",
    top: "30px", /* Adjust the position as needed */
    left: "50%",
    transform: "translateX(-50%)",
    maxWidth: "400px", /* Adjust size as needed */
    height: "auto"}}
            />
        </div>
      

              {/* <img src="/images/MicroSite/call-center.jpg"></img> */}
              <div style={{padding: "15px"}}>
              <Typography variant="h4" component="div" gutterBottom style={{color: "#fff", textAlign: "center", paddingTop: "50px", fontWeight: "bolder"}}>Selección de circuitos para la familia Bonilla</Typography>
              <Typography variant="h5" component="div" gutterBottom style={{color: "#fff", textAlign: "center"}}><b>Agente:</b> Felipe Rebolledo</Typography>
              </div>
          {/* </div> */}
                <Stack alignItems="center">
              
              </Stack>
              <Stack
                sx={{
                //   background: "#fff",
                //   borderColor: Colors.blue_ibero,
                  padding: "15px",
                //   borderRadius: "15px",
                //   borderStyle: "solid",
                //   borderWidth: "1px",
                  m: "15px",
                }}
              >      
           <Grid  container
      spacing={{ xs: 1, md: 2 }}
      justifyContent="center"
      sx={{ margin: `20px 4px 10px 4px` }}
      columns={{ xs: 4, sm: 8, md: 12 }}>
             { data.map((item, index) => (
                <Grid item xs={3} key={index}>
                <a href="https://iberolunatravel.com/web/Contenido//trabax/2024/eurissima-54.pdf" target="blank">
                        <StyledCard >
                    <CardMedia
                        component="img"
                        image={item.imageCircuit}
                        alt={item.nameCircuit}
                        height="350px"
                    />                    
                    <GradientBox>
                        <Box sx={{ fontWeight: 'bold', m: 1,  textAlign: 'center' }}><Typography variant="h6" component="div" gutterBottom>
                            {item.nameCircuit}
                        </Typography></Box>
                        <InfoBox>
                            <InfoItem style={{background: Colors.orange_ibero, marginRight: "7px"}}>
                                <Typography variant="body2" component="div">{item.daysCircuit} días</Typography>
                            </InfoItem>
                            <InfoItem style={{background: Colors.blue_ibero}}>
                                <Typography variant="body2" component="div">{item.quantityCitiesCircuit} ciudades</Typography>
                            </InfoItem>
                            {/* <StyledButton href="#">Ver Circuito</StyledButton> */}
                        </InfoBox>
                    </GradientBox>
                </StyledCard>
                </a>
                </Grid>
            ))}  
            </Grid>            
            </Stack> 
            </div>          
            <div style={{background: "#fff"}}>
            <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          p: 3,
          m: 1,
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        <Typography variant="h6" component="div" gutterBottom style={{color: "#373A40", textAlign: "center"}}><b>Agente: </b> Felipe Rebolledo</Typography>
        <a
                aria-label="Chat on WhatsApp"
                href="https://api.whatsapp.com/send/?phone=%2B573147007919&text&type=phone_number&app_absent=0" target="_blank"  rel="noreferrer"
              >
                {" "}
                <img
                  alt="Chat on WhatsApp"
                  src="/images/WhatsAppButtonGreenSmall.svg"
                />{" "}
              </a>
              <Typography variant="h6" component="div" gutterBottom style={{color: "#373A40", textAlign: "center"}}><b>Teléfono contacto: </b> (602) 251 6687</Typography>
      </Box>
            </div>
        </UIProvider>
        </Stack>
      {/* </Container> */}
    {/* </ThemeProvider> */}
    </Fragment>
    );
};

export default TravelCard;
