import {
  Button,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  Typography,
  IconButton,
} from "@mui/material";
import theme, { Colors } from "../../styles/theme";
import {
  AppbarContainer,
  AppbarHeaderImage,
  MyList,
} from "../../styles/appbar";
import { useUIContext } from "../../context/ui";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ContactModal from "../ContactModal"
import { Fragment } from "react";


export default function AppbarDesktop({ matches }) {
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openM, setOpenM] = useState(false);
  const { setShowSearchBox } = useUIContext();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnClickHome = useCallback(
    () => navigate("/Home", { replace: true }),
    [navigate]
  );
  const handleOnClickCatalogos = useCallback(
    () => navigate("/Catalogos", { replace: true }),
    [navigate]
  );
  const handleOnClickCircuitos = useCallback(
    () => navigate("/results/Circuitos/*", { replace: true }),
    [navigate]
  );
  const handleOnClickOfertas = useCallback(
    () => navigate("/results/Ofertas/*", { replace: true }),
    [navigate]
  );
  const handleOnClickQuienesSomos = useCallback(
    () => navigate("/quienessomos", { replace: true }),
    [navigate]
  );
  const handleOnClickContactenos = useCallback(
    () => navigate("/contacto", { replace: true }),
    [navigate]
  );
  const handleOnClickReserva = useCallback(
    () => navigate("/consultareserva/All/All", { replace: true }),
    [navigate]
  );
  const handleOnClickPresupuesto = useCallback(
    () => navigate("/consultapresupuesto/All/All", { replace: true }),
    [navigate]
  );

  const handleCloseM = () => setOpenM(false);

    useEffect(() => {
    const timer = setTimeout(() => {
      console.log(matches);
      setOpenM(true);
    }, 300000); 

    // Cleanup function to clear the timeout if the component unmounts before the timeout completes
    return () => {
      clearTimeout(timer);
    };
  }, []);


  return (
    <AppbarContainer sx={{ backgroundColor: Colors.light_gray, boxShadow: 1 }}>
      {/* <AppbarHeader variant="h4">My Bags</AppbarHeader> */}
      <Link to={"/home"}>
        <AppbarHeaderImage src="/images/branding/LogoIberoluna.png"  alt="Viajes Iberoluna" title="Viajes Iberoluna"/>
      </Link>
      <MyList type="row">
        <ListItemButton align="center" onClick={handleOnClickHome}>
          <ListItemText align="center" primary="INICIO" />
        </ListItemButton>
        <ListItemButton align="center" onClick={handleOnClickCatalogos}>
          <ListItemText align="center" primary="DESTINOS" />
        </ListItemButton>
        <ListItemButton align="center" onClick={handleOnClickCircuitos}>
          <ListItemText align="center" primary="¿DÓNDE DESEA VIAJAR?" />
        </ListItemButton>
        {/* <ListItemButton align="center" onClick={handleOnClickOfertas}>
          <ListItemText align="center" primary="BUSCADOR DE OFERTAS" />
        </ListItemButton> */}
        <ListItemButton align="center" onClick={handleOnClickContactenos}>
          <ListItemText align="center" primary="CONTÁCTENOS" />
        </ListItemButton>
        <ListItemButton align="center" onClick={handleClick}>
          <ListItemText align="center" primary="CONSULTAS" />
        </ListItemButton>       
        <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
          <MenuItem onClick={handleOnClickReserva}>Consulta de Reservas</MenuItem>
          <MenuItem onClick={handleOnClickPresupuesto}>Consulta de Presupuesto</MenuItem>
        </Menu>
        {/*  <ListItemButton onClick={() => setShowSearchBox(true)}>
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
        </ListItemButton> */}
      </MyList>
      {/* <Actions matches={matches} /> */}
     

      <Dialog fullScreen={fullScreen} open={openM} onClose={handleCloseM}>
        <DialogTitle sx={{ m: 0, p: 1 }}>
          <br></br>
          <Typography
            align="center"
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              color: Colors.orange_ibero,
              fontWeight: "Medium",
            }}
          >
            ¿Necesitas asesoría para tu viaje?
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseM}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
        <ContactModal onClick={handleCloseM} matches={matches}></ContactModal>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions> */}
      </Dialog>
    </AppbarContainer>
  );
}
