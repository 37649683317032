import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import CircuitsresultsDesktop from "./circuitsresultsDesktop";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function Titlebar() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [ciudades, setCiudades] = useState([]);
  const [paises, setPaises] = useState([]);
  const [folletos, setFolletos] = useState([]);
  const [ciudadesCriterio, setCiudadesCriterio] = useState([]);
  const [paisesCriterio, setPaisesCriterio] = useState([]);
  const [diasCriterio, setDiasCriterio] = useState({});

  const params = useParams();
  const tipo = params.tipo;
  const codfolleto = params.folleto === "All" ? "*" : params.folleto;
  const fecha = params.fecha;
  const paisesParam = params.paises;
  const ciudadesParam = params.ciudades;
  const dias = params.dias;

  // console.log(params);

  useEffect(() => {
    // 👇️ only runs once
    window.scrollTo(0, 0);
    var pp = {code: "Todos", label: "Todos"};
    if (dias === undefined || dias === "All") {

    } else {
      pp = {code: dias, label: dias};
    }
    // console.log("pp");
    // console.log(pp);
    setDiasCriterio(pp);
    fillFolletos()
     fillPaises();    
    fillCiudades();    
  }, []);

  const fillPaises = async () => {
    const url = "https://iberolunatravel.online/wse/api/getPaises";
    //  const url = "../../data/paises.json";
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });    
    // console.log("paisesParam");
    // console.log(paisesParam);
    setPaises(resp.data);
    if (paisesParam !== undefined && paisesParam !== "All") {
    const array = [];
    paisesParam.split(",").forEach(function (pais, key) {
      const elementToAdd = resp.data.find(
        (element) => element.PAIS === pais
      );

      array.push(elementToAdd);
    });    
    // console.log("array");
    // console.log(array);
    setPaisesCriterio(array);
  }
    //return resp.data;
  };

  const fillCiudades = async () => {
    const url = "https://iberolunatravel.online/wse/api/getCiudades";
    // const url = "../../data/ciudades.json";
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    setCiudades(resp.data);
    if (ciudadesParam !== undefined && ciudadesParam !== "All") {
      const array = [];
      ciudadesParam.split(",").forEach(function (ciudad, key) {
        const elementToAdd = resp.data.find(
          (element) => element.CIUDAD === ciudad
        );
        array.push(elementToAdd);
      });
      setCiudadesCriterio(array);
    } 
    //return resp.data;
  };

  const fillFolletos = async () => {
    const url =
      "https://iberolunatravel.online/wse/api/getFolletosCircuito";
    // const url = "../../data/folletos.json";
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    setFolletos(resp.data);   
  };

  return (
    <>
      {matches ? (
        <CircuitsresultsDesktop
          ciudadesData={ciudades}
          paisesData={paises}
          folletosData={folletos}
          matches={matches}
          tipo={tipo}
          codfolleto={codfolleto}
          fecha={fecha}
          paisesCriterio={paisesCriterio}
          ciudadesCriterio={ciudadesCriterio}
          dias={diasCriterio}
        />
      ) : (
        <CircuitsresultsDesktop
          ciudadesData={ciudades}
          paisesData={paises}
          matches={matches}
          folletosData={folletos}
          tipo={tipo}
          codfolleto={codfolleto}
          fecha={fecha}
          paisesCriterio={paisesCriterio}
          ciudadesCriterio={ciudadesCriterio}
          dias={diasCriterio}
        />
      )}
    </>
  );
}
